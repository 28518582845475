'use strict';

angular.module('enervexSalesappApp').directive('fileUpload', function() {
	return {
		restrict: 'E',
		template: '\
			<div class="drop-zone" ng-class="{ \'drag-over\': isDragOver }">\
				<p class="input-area">\
					<label for="inputFile" class="file-page-only">\
						<span class="fa fa-paperclip"></span><br/>\
						Drop a file here to upload, or <br/> \
						<span class="standard-link">click here to browse files from your computer</span>\
					</label>\
					<input name="inputFile" id="inputFile" type="file" uploader="uploader">\
					</input>\
				</p>\
			</div>\
		',
		link: function(scope, element) {
			scope.isDragOver = false;
			scope.file = null;
			element.on('dragover', function(event) {
				event.preventDefault();
				event.stopPropagation();
				scope.$apply(function() {
					scope.isDragOver = true;
				});
			});

			element.on('dragleave', function(event) {
				event.preventDefault();
				event.stopPropagation();
				scope.$apply(function() {
					scope.isDragOver = false;
				});
			});

			element.on('drop', function(event) {
				event.preventDefault();
				event.stopPropagation();
				scope.$apply(function() {
					scope.isDragOver = false;
					var files = event.originalEvent.dataTransfer.files;
					if (files.length > 0) {
						scope.file = files[0];
						scope.uploader.addToQueue(scope.file);
						scope.uploader.uploadAll();
					}
				});
			});

			// Prevent the default behavior of the 'drop' event on the window object
			angular.element(window).on('drop', function(event) {
				event.preventDefault();
				event.stopPropagation();
			});
		}
	};
});